<template>
  <v-menu
    v-model="showPicker"
    v-bind="attributes.menu"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        :value="displayedDate"
        class="pt-0 input--date-picker"
        :rules="showPicker ? [] : validators"
        :label="field.text"
        :placeholder="field.placeholder"
        v-bind="{...attrs, ...attributes.textField}"
        v-on="on"
        @click.native="showPicker = !showPicker"
      >
        <template #prepend-inner>
          <Icon
            name="date"
            size="small"
            class="mr-2"
          />
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      v-bind="attributes.datePicker"
      :min="minDate"
      @input="showPicker = false"
    />
  </v-menu>
</template>

<script>
import inputMixin from '../../../mixins/InputMixin.vue'
import get from 'lodash/get'
import { dateStringFormat } from '../../../utils'
import attributes from '../../../const/datePickerAttrributes'
import DateTime from 'luxon/src/datetime'

export default {
  mixins: [inputMixin],
  data: () => ({
    attributes,
    showPicker: false,
    displayedDate: null
  }),
  computed: {
    date: {
      get () {
        const date = get(this.data, this.field.value)
        return date ? new Date(date).toISOString() : ''
      },
      set (date) {
        this.displayedDate = this.formatDate(date)
        this.$emit('update', { [this.field.name]: date })
      }
    },
    minDate () {
      return DateTime.utc().toISODate()
    }
  },
  mounted () {
    let date = get(this.data, this.field.value)
    date = date ? new Date(date).toISOString() : ''
    this.displayedDate = this.formatDate(date)
  },
  methods: {
    formatDate (date) {
      return date ? dateStringFormat(date) : ''
    }
  }
}
</script>
